import {
    SET_CONFIGURATOR_CATEGORY,
    SET_CONFIGURATOR_TYPE,
    SET_CONFIGURATOR_SEX,
    SET_CONFIGURATOR_SIZE,
    SET_CONFIGURATOR_FACE,
    SET_CONFIGURATOR_COLOR,
    SET_CONFIGURATOR_IMAGE
} from '../actions/configurator';

import updateObject from "../updateObject";

const initialState = {
    category: "none",
    type: "none",
    sex: "none",
    size: "none",
    face: "none",
    color: "none",
    image: "https://imgur.com/bFyB2SS.png"
};

export function SetConfiguratorColor(state, action) {
    return updateObject(state, {
        color: action.item
    });
}

export function SetConfiguratorFace(state, action) {
    return updateObject(state, {
        face: action.item
    });
}

export function SetConfiguratorImage(state, action) {
    return updateObject(state, {
        image: action.item
    });
}

export function setConfiguratorSex(state, action) {
    return updateObject(state, {
        sex: action.item,
        type: "none",
        face: "front",
        color: "none"
    });
}

export function SetConfiguratorSize(state, action) {
    return updateObject(state, {
        size: action.item
    });
}

export function SetConfiguratorType(state, action) {
    return updateObject(state, {
        type: action.item,
        face: "front",
        color: "none",
    });
}

export function SetConfiguratorCategory(state, action) {
    return updateObject(state, {
        category: action.item,
        sex: "none",
        type: "none",
        face: "none",
        color: "none",
    });
}

export function configuratorReducer(state = initialState, action) {
    switch (action.type) {
        case SET_CONFIGURATOR_CATEGORY: return SetConfiguratorCategory(state, action);
        case SET_CONFIGURATOR_TYPE: return SetConfiguratorType(state, action);
        case SET_CONFIGURATOR_FACE: return SetConfiguratorFace(state, action);
        case SET_CONFIGURATOR_COLOR: return SetConfiguratorColor(state, action);
        case SET_CONFIGURATOR_IMAGE: return SetConfiguratorImage(state, action);
        case SET_CONFIGURATOR_SEX: return setConfiguratorSex(state, action);
        case SET_CONFIGURATOR_SIZE: return SetConfiguratorSize(state, action);

        default: return state;
    }
}