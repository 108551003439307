import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { setConfiguratorCategory, setConfiguratorImage, setConfiguratorSex, setConfiguratorType } from "../../../../redux/actions/configurator";

import { Menu } from "../Navbar";
import { Image } from "../Lazyload";

const Header = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const categories = useSelector((state) => state.categories.data);
  const cart = useSelector((state) => state.cart.data);
  const wishlist = useSelector((state) => state.wishlist.data);
  const user = useSelector((state) => state.account.data);

  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 1440;
  const [showMenu, setShowMenu] = useState(false);
  const [showCart, setShowCart] = useState(false);
  const [showWishlist, setShowWishlist] = useState(false);

  const handleMenu = (e) => {
    e?.preventDefault();
    setShowMenu(!showMenu);
  };

  const handleCart = (e) => {
    e?.preventDefault();
    setShowCart(!showCart);
  };

  const handleWishlist = (e) => {
    e?.preventDefault();
    setShowWishlist(!showWishlist);
  };

  const getCartLength = () => {
    let length = 0;

    cart?.length > 0 &&
      cart?.map((item) => {
        length += item.amount;
      });
    return length;
  };

  return (
    <header>
      <div className="d-flex flex-row justify-content-between">
        {!isMobile ? (
          <>
            <div className="d-flex flex-row justify-content-between w-100 mx-5">
              <div className="nav my-auto">
                <Link className="nav__option" to="/">
                  Acasa
                </Link>
                <div className="nav__option">
                  Categorii <div className="arrow__down"></div>
                  <div className="nav__dropdown">
                    {categories
                      .filter((value) => value.type=="primary")
                      .map((category, index) => (
                        <Link
                          key={index}
                          to={`/configurator`}
                          className="choice"
                          onClick={() =>
                            {
                              dispatch(setConfiguratorCategory(category.hotkey=="familypack" ? "pack" : category.hotkey=="accesory" ? "pillow" : "clothes"));

                              if (category.hotkey != "familypack" && category.hotkey != "accesory") {
                                dispatch(setConfiguratorSex("women"));
                                dispatch(setConfiguratorType(category.hotkey));
                              }
                            }
                          }
                        >
                          {category.title}
                        </Link>
                      ))}
                      <Link
                        to={`/configurator?type=animalut`}
                        className="choice"
                        onClick={() =>
                          {
                            dispatch(setConfiguratorCategory("animalut"));
                            dispatch(setConfiguratorSex("women"));
                            dispatch(setConfiguratorType("hoodie"));
                            dispatch(setConfiguratorImage("https://imgur.com/yqLG7x9.png"));
                          }
                        }
                      >
                        Animalutul tau - portret brodat
                      </Link>
                  </div>
                </div>
                <Link className="nav__option" to="/contact">
                  Contacteaza-ne
                </Link>
                <Link className="nav__option" to="/about">
                  Despre noi
                </Link>
                <Link className="nav__option nav__option--dark" to="/configurator">
                  CONFIGURATOR
                </Link>
              </div>

              <Link to="/">
                <Image
                  className="logo"
                  src={require("../../../../assets/logo.png").default}
                  alt="Logo."
                />
              </Link>

              <div className="my-auto d-flex flex-row justify-content-center gap-4">
                {
                  JSON.stringify(user?.data)=="{}" &&
                  <Link className="nav__option nav__option--dark mt-1" to="/register">
                    Sign Up
                  </Link>
                }

                <Link
                  className="nav__button"
                  to={
                    JSON.stringify(user?.data) != "{}" ? "/account" : "/login"
                  }
                >
                  <Image
                    className="nav__button__img"
                    src={require("../../../../assets/account.svg").default}
                    alt="My account."
                  />
                </Link>

                {/* <Image className="nav__button" src={require("../../../../assets/search.png").default} alt="Search item." /> */}
                <Link className="nav__button" to="/cart">
                  <Image
                    className="nav__button__img"
                    src={require("../../../../assets/cart.svg").default}
                    alt="My shopping cart."
                  />
                  <div className="nav__button__count">
                    {getCartLength(cart)}
                  </div>
                </Link>
                <Link className="nav__button" to="/wishlist">
                  <Image
                    className="nav__button__img"
                    src={require("../../../../assets/wishlist.svg").default}
                    alt="Wishlist."
                  />
                  <div className="nav__button__count">
                    {Object.keys(wishlist)?.length}
                  </div>
                </Link>
              </div>
            </div>
          </>
        ) : (
          <div className="d-flex flex-row justify-content-between align-items-center w-100">
            <Menu show={showMenu} onClose={handleMenu} />

            <div className="nav__button p-1 w-25" onClick={handleMenu}>
              <Image
                className="nav__button__img"
                src={require("../../../../assets/menu.svg").default}
                alt="My menu."
              />
            </div>

            <Link to="/" className="d-flex flex-row justify-content-center align-items-center py-2 gap-1">
              <Image
                className="logo"
                src={require("../../../../assets/logo--simple.svg").default}
                alt="logo sufy :)"
              />
            </Link>

            <div className="d-flex flex-row justify-content-end gap-2 w-25">
              <Link className="nav__button p-1" to="/cart">
                <Image
                  className="nav__button__img"
                  src={require("../../../../assets/cart.svg").default}
                  alt="My shopping cart."
                />
                <div className="nav__button__count">
                  {getCartLength(cart)}
                </div>
              </Link>

              <Link className="nav__button p-1" to="/wishlist">
                <Image
                  className="nav__button__img"
                  src={require("../../../../assets/wishlist.svg").default}
                  alt="Wishlist."
                />
                <div className="nav__button__count">
                  {Object.keys(wishlist)?.length}
                </div>
              </Link>
            </div>
          </div>
        )}
      </div>
    </header >
  );
};

export default Header;