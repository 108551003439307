import React, { useState, useRef, useEffect } from "react";
import Slider from "react-slick";

import { Image } from "../utils/Lazyload";

const SlideShow = ({images}) => {
    const [width, setWidth] = useState(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const isMobile = width <= 768;
    const sliderRef = useRef();
    const [currentImage, setCurrentImage] = useState(0);
    
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,    
        slidesToScroll: 1
    };

    return (
        <div className="images">
            <Slider 
                className="preview slider" 
                {...settings}
                ref={sliderRef}
                beforeChange={(current, next) => setCurrentImage(next)}
            >
                {
                    images.map((item, idx) => (
                        <Image 
                            key={idx}
                            className="slide" 
                            src={item} 
                            alt="slide"
                        />
                    ))
                }
            </Slider>


            <div className={isMobile ? "d-flex flex-row justify-content-start gap-2 p-2 ps-0 pe-0" : "d-flex flex-column justify-content-start gap-2 p-2 ps-0 pe-0"}>
                {
                    images.map((item, idx) => (
                        <Image 
                            key={idx}
                            className="image" 
                            src={item} 
                            alt="slide" 
                            onClick={() => {
                                setCurrentImage(idx);
                                sliderRef.current.slickGoTo(idx);
                            }}
                            style={{
                                "opacity": idx==currentImage ? 1 : 0.5
                            }}
                        />
                    ))
                }
            </div>
        </div>
    );
}

export default SlideShow;