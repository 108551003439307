import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Slider from "react-slick";
import axios from "axios";

import { Image } from "../utils/Lazyload";
import { ImageUploader } from "../utils/Upload";

import { editPage } from "../../../redux/actions/page";

const Slide = ({ accountRole, gallery, setGallery }) => {
    const [width, setWidth] = useState(window.innerWidth);

    const [editMode, setEditMode] = useState(false);
    const [images, setImages] = useState(gallery);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener("resize", handleWindowSizeChange);
        return () => {
            window.removeEventListener("resize", handleWindowSizeChange);
        };
    }, []);

    const isMobile = width <= 1024;

    const uploadImage = async (image, cb) => {
        const formData = new FormData();
        formData.append("image", image);

        await axios.post(`${process.env.REACT_APP_API_KEY}/files/options`,
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    "Authorization": "Token " + localStorage.getItem("token")
                }
            })
            .then(res => {
                cb(res.data.link);
            }).catch(err => { });
    }

    return (
        <div className="slide">
            <Image className="background" src={isMobile ? gallery.phone : gallery.desktop} alt='index' />

            {
                accountRole == "master" ?
                    <div className="overlay">
                        {
                            editMode ?
                                <div className="d-flex flex-row flex-wrap">
                                    <ImageUploader value={images.desktop || gallery.desktop} setImage={(newImage) => {
                                        console.log(newImage);
                                        setImages({ ...images, desktop: newImage });
                                    }} />
                                    <ImageUploader value={images.phone || gallery.phone} setImage={(newImage) => {
                                        setImages({ ...images, phone: newImage });
                                    }} />

                                    <div className="m-4 user-select-none">
                                        <span className="material-icons-outlined pointer" onClick={async () => {
                                            let newGallery = gallery;

                                            if (typeof images.desktop!= "string") {
                                                await uploadImage(images.desktop, (link) => {
                                                    newGallery.desktop = link;
                                                });
                                            }
                                            
                                            if (typeof images.phone!= "string") {
                                                await uploadImage(images.phone, (link) => {
                                                    newGallery.phone = link;
                                                });
                                            }

                                            setGallery(newGallery);
                                            setEditMode(!editMode);
                                        }}>done</span>
                                    </div>
                                    <div className="m-4 user-select-none">
                                        <span className="material-icons-outlined pointer" onClick={() => { setEditMode(!editMode);setImages({ desktop: null, phone: null })}}>close</span>
                                    </div>
                                </div>
                                :
                                <div className="m-4 mx-auto user-select-none">
                                    <span className="material-icons-outlined pointer" onClick={() => setEditMode(!editMode)}>edit</span>
                                </div>
                        }
                    </div>
                    :
                    <></>
            }
        </div>
    );
}

const Slideshow = ({ gallery }) => {
    const dispatch = useDispatch();

    const slider = React.useRef(null);

    const page = useSelector(state => state.page);
    const account = useSelector(state => state.account.data);

    const settings = {
        arrows: false,
        dots: false,
        pauseOnHover: true,
        infinite: true,
        speed: 1000,
        // autoplay: true,
        fade: false,
        variableWidth: false,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    return (
        <div className="landing__slider">
            <div className="slider__arrows">
                <div className="arrow-btn prev" onClick={() => slider?.current?.slickPrev()}>
                    <span className="material-icons-outlined">
                        arrow_back_ios
                    </span>
                </div>
                <div className="arrow-btn next" onClick={() => slider?.current?.slickNext()}>
                    <span className="material-icons-outlined">
                        arrow_forward_ios
                    </span>
                </div>
            </div>

            <Slider className="slider" {...settings} ref={slider}>
                {
                    gallery?.map((imageset, index) => (
                        <Slide key={index} accountRole={account?.type} gallery={imageset} setGallery={
                            (newGallery) => (
                                dispatch(editPage({
                                    value: JSON.stringify([...gallery.slice(0, index), newGallery, ...gallery.slice(index + 1)]),
                                    name: "landing_gallery"
                                })
                            ))
                        } />
                    ))
                }
            </Slider>
        </div>
    );
}

export default Slideshow;