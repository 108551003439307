import React, { useState, useEffect } from 'react';

import { useDispatch, useSelector } from "react-redux";

import { editPage } from '../../../redux/actions/page';

const Price = ({ parameterToEdit, indexes, price }) => {
    const dispatch = useDispatch();
    const account = useSelector(state => state.account.data);

    const [width, setWidth] = useState(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const isMobile = width <= 768;

    const [priceState, setPriceState] = useState(price);
    const [editMode, setEditMode] = useState(false);

    useEffect(() => {
        setPriceState(price);
    }, [price]);

    return (
        <div className="d-flex flex-row m-auto w-100">
            {
                editMode ?
                    <input className={isMobile ? "text-center w-75" : "text-center"} value={priceState} onChange={e => setPriceState(e.target.value)} />
                    :
                    <div>{Number(priceState).toLocaleString("ro-RO", { style: "currency", currency: "RON" })}</div>
            }

            {
                account?.type=="master" &&
                (
                    editMode ?
                        <div className="d-flex flex-row m-auto">
                            <div className="user-select-none">
                                <span className="material-icons-outlined pointer fs-5" onClick={() => {
                                    setEditMode(!editMode);

                                    let parameter = parameterToEdit.content;

                                    if (indexes.length==3) {
                                        parameter[indexes[0]][indexes[1]][indexes[2]].price = Number(priceState.replace(",", "."));
                                    }
                                    else if (indexes.length==2) {
                                        parameter[indexes[0]][indexes[1]].price = Number(priceState.replace(",", "."));
                                    }
                                    else {
                                        parameter[indexes[0]].price = Number(priceState.replace(",", "."));
                                    }

                                    dispatch(editPage({
                                        value: JSON.stringify({ ...parameter }),
                                        name: parameterToEdit.name
                                    }))
                                }}>done</span>
                            </div>
                            <div className="user-select-none">
                                <span className="material-icons-outlined pointer fs-5" onClick={() => { setEditMode(!editMode); setPriceState(price) }}>close</span>
                            </div>
                        </div>
                        :
                        <div className="user-select-none">
                            <span className="material-icons-outlined pointer fs-5" onClick={() => setEditMode(!editMode)}>edit</span>
                        </div>
                )
            }
        </div>
    )
}

export default Price;