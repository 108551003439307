import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { editPage } from '../../../redux/actions/page';

const Texts = ({ texts, large }) => {
    const dispatch = useDispatch();
    const account = useSelector(state => state.account.data);

    const [textsState, setTextsState] = useState(texts);
    const [editMode, setEditMode] = useState(false);
    const ref = useRef(null);

    const handleInput = (e, textIdx) => {
        setTextsState({ ...textsState, [textIdx]: e.target.value });

        e.target.style.height = 'inherit';
        e.target.style.height = `${e.target.scrollHeight}px`;
    }

    useEffect(() => {
        if (editMode) {
            ref.current.style.height = 'inherit';
            ref.current.style.height = `${ref.current.scrollHeight}px`;
        }
    }, [editMode]);

    return (
        <div className="d-flex flex-row justify-content-start input__group m-auto w-100">
            {
                Object.keys(textsState)?.map((textIdx, idx) => (
                    editMode ?
                        large ?
                            <textarea ref={ref} key={idx} className="w-100" value={textsState[textIdx]} onChange={e => handleInput(e, textIdx)} />
                            :
                            <input key={idx} className="text-center" value={textsState[textIdx]} onChange={e => setTextsState({ ...textsState, [textIdx]: e.target.value })} />
                        :
                        <div key={idx} className="w-100">{textsState[textIdx]}</div>
                ))
            }

            {
                account?.type=="master" &&
                (
                    editMode ?
                        <div className="d-flex flex-row m-auto">
                            <div className="user-select-none">
                                <span className="material-icons-outlined pointer fs-5" onClick={(e) => {
                                    setEditMode(!editMode);
    
                                    Object.keys(textsState)?.map((textIdx, idx) => {
                                        dispatch(editPage({
                                            value: textsState[textIdx],
                                            name: textIdx
                                        }))
                                    })
                                }}>done</span>
                            </div>
                            <div className="user-select-none">
                                <span className="material-icons-outlined pointer fs-5" onClick={() => { setEditMode(!editMode); setTextsState(texts) }}>close</span>
                            </div>
                        </div>
                        :
                        <div className="user-select-none">
                            <span className="material-icons-outlined pointer fs-5" onClick={() => setEditMode(!editMode)}>edit</span>
                        </div>
                ) 
            }
        </div>
    )
}

export default Texts;