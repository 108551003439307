import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const PageHeader = (props) => {
    return (
        <div className="page-header">
            <div className="subtitle text-gray"><Link to="/" className="text-dark">Acasa</Link> / <span className="text-gray">{props.page}</span></div>
        </div>
    );
}

export default PageHeader;