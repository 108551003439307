import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { setFilterCategories } from "../../../../redux/actions/filters";
import LogoProject13 from "../../../../assets/logo-project13.png";

import { Image } from "../Lazyload";

const Header = () => {
    const dispatch = useDispatch();
    const categories = useSelector(state => state.categories.data);
    const [width, setWidth] = useState(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const isMobile = width <= 768;

    return (
        <footer>
            <div className={isMobile ? "d-flex flex-column justify-content-center text-center gap-3" : "d-flex flex-row justify-content-between"}>
                <div className="d-flex flex-column justify-content-start mt-0">
                    <Link to="/" className="d-flex flex-row justify-content-center align-items-center py-2 gap-1">
                        <Image
                            className="logo"
                            src={require("../../../../assets/logo--simple.svg").default}
                            alt="logo :)"
                        />
                    </Link>
                    <div className="d-flex flex-row justify-content-center gap-2">
                        <div className="choice">
                            <Image src={require("../../../../assets/facebook.png").default} alt="facebook" />
                        </div>
                        <div className="choice">
                            <Image src={require("../../../../assets/instagram.png").default} alt="instagram" />
                        </div>
                        <div className="choice">
                            <Image src={require("../../../../assets/tiktok.png").default} alt="tiktok" />
                        </div>
                    </div>
                </div>
                <div className={isMobile ? "d-flex flex-column justify-content-center gap-5 mt-0" : "d-flex flex-row justify-content-center gap-5 mt-0"}>
                    <div className="d-flex flex-column justify-content-start mt-0">
                        <div className="color--black">Pagina noastra</div>
                        <Link to="/contact" className="color--gray">Contacteaza-ne</Link>
                        <Link to="/about" className="color--gray">Despre noi</Link>
                        <Link to="/size-guide" className="color--gray">Tabel dimensiuni</Link>
                        <Link to="/gallery" className="color--gray">Galerie</Link>
                    </div>
                    <div className="d-flex flex-column justify-content-start mt-0">
                        <div className="color--black">Politiciile noastre</div>
                        <Link to="/gdpr" className="color--gray">GDPR</Link>
                        <Link to="/tos" className="color--gray">Termeni si conditii</Link>
                        <Link to="/privacy" className="color--gray">Politica de confidentialitate</Link>
                        <Link to="/shipping" className="color--gray">Politica de transport</Link>
                        <Link to="/return" className="color--gray">Politica de retur</Link>
                    </div>
                    <div className="d-flex flex-column justify-content-start mt-0">
                        <div className="color--black">Categoriile noastre</div>
                        {
                            categories.filter(value => value.type=="primary").map((category, index) => (
                                <Link key={index} to={`/products`} className="color--gray"  onClick={() => dispatch(setFilterCategories([category]))}>{category.title}</Link>
                            ))
                        }
                    </div>
                </div>
                <Image className={isMobile ? "w-50 m-auto" : ""} src="https://imgur.com/ycWGJkh.png" alt="librapay" />
            </div>
            
            <div class="disclaimer">
                <img src={LogoProject13} alt="project13" class="logo" />

                <a href="https://project13.ro">project13.ro</a>
            </div>

            <div className="copyright">
                <div className="text-center">
                    Copyright &copy; 2023 Desen Brodat - All rights reserved.
                </div>

                <div className={isMobile ? "d-flex flex-column justify-content-center text-center gap-1" : "d-flex flex-row justify-content-center gap-4"}>
                    <a href="https://anpc.ro/">ANPC - Autoritatea Nationala pentru Protectia Consumatoriilor.</a>
                    <a href="https://anpc.ro/ce-este-sal/">ANPC - Solutionarea Alternativa a Litigiilor.</a>
                </div>
            </div>
        </footer>
    );
}

export default Header;