import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Image } from '../utils/Lazyload';
import { Texts } from '../Editable';

const Stories = ({ title, images }) => {
    const [width, setWidth] = useState(window.innerWidth);
    const account = useSelector(state => state.account.data);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const isMobile = width <= 768;

    return (
        <div className={isMobile ? "d-flex flex-column input__group m-auto w-100" : "d-flex flex-column input__group m-auto p-0 fitswell"}>
            <h1 className={isMobile ? "text-center fs-4 text-black bold" : "text-start fs-4 text-black bold"}>
                <Texts texts={{ landing_stories_title: title }} />
            </h1>

            <div className="landing__gallery m-2">
                {
                    JSON.parse(images)?.slice(0, 4)?.map((story, idx) => (
                        <div key={idx} className="story">
                            <Image className="story__image" src={story.image} alt='index' />
                            <div className="story__content"></div>
                        </div>
                    ))
                }
            </div>

            <Link className="button m-auto w-fit" to="/gallery">
                Vezi mai multe
            </Link>
        </div>
    )
}

export default Stories;