import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { editPage } from "../../../redux/actions/page";
import Slider from "react-slick";


const Review = ({ accountType, reviews, setReviews, removeReviews, pause, setPause }) => {
    const [editMode, setEditMode] = useState(false);
    const [person, setPerson] = useState(reviews.person);
    const [text, setText] = useState(reviews.content);

    useEffect(() => {
        if (editMode) {
            setPause(true);
        }
        else {
            setPause(false);
        }
    }, [editMode]);

    return (
        <div className="review">
            <div className="d-flex flex-row justify-content-between w-100">
                <div className="review__person">
                    {
                        editMode ?
                        <img className="avatar" src={person.type=="girl" ? "https://imgur.com/6cO2136.png" : "https://imgur.com/AkxIDcF.png"} onClick={() => setPerson({ ...person, type: person.type=="girl" ? "boy" : "girl"})} alt="Client's image." />
                        :
                        <img className="avatar" src={person.type=="girl" ? "https://imgur.com/6cO2136.png" : "https://imgur.com/AkxIDcF.png"} alt="Client's image." />
                    }

                    <div className="d-flex flex-column justify-content-start align-items-start">

                        {
                            editMode ?
                                <input className="text-center w-100" value={person.name} onChange={e => setPerson({ ...person, name: e.target.value })} />
                                :
                                <div className="whitespace-break-spaces w-100 name" dangerouslySetInnerHTML={{ __html: person.name }}></div>
                        }


                        {
                            editMode ?
                                <input type="number" min={0} max={5} className="text-center w-100" value={person.stars} onChange={e => setPerson({ ...person, stars: e.target.value })} />
                                :
                                <div className="d-flex flex-row justify-content-center align-items-start">
                                    {
                                        new Array(Math.floor(person.stars)).fill(0).map((_, idx) => (
                                            <img key={idx} src={require("../../../assets/star-filled.svg").default} alt="Star." />
                                        ))
                                    }
                                    {
                                        new Array(5 - Math.floor(person.stars)).fill(0).map((_, idx) => (
                                            <img key={idx} src={require("../../../assets/star-outlined.svg").default} alt="Star." />
                                        ))
                                    }
                                </div>
                        }
                    </div>
                </div>
            </div>
            <div className={`review__content w-100 ${editMode ? "review__content--edit" : ""}`}>
                {
                    editMode ?
                        <textarea className="text-start w-100" value={text} onChange={e => setText(e.target.value)} />
                        :
                        <div className="whitespace-break-spaces w-100" dangerouslySetInnerHTML={{ __html: text }}></div>
                }

                {
                    accountType=="master" &&
                    (
                        editMode ?
                            <div className="d-flex flex-row m-auto mt-3">
                                <div className="user-select-none">
                                    <span className="material-icons-outlined pointer" onClick={async () => {
                                        setEditMode(!editMode);

                                        if (text=="" || person.name=="" || person.stars=="") {
                                            setText(reviews.content.replace(/\n/g, "<br />").replace(/\\n/g,"<br />").replace(/\\/g,"")); setPerson(reviews.person.replace(/\n/g, "<br />").replace(/\\n/g,"<br />").replace(/\\/g,""));
                                            return alert("Toate campurile sunt obligatorii.");
                                        }

                                        if (text.length >= 128) {
                                            setText(reviews.content.replace(/\n/g, "<br />").replace(/\\n/g,"<br />").replace(/\\/g,"")); setPerson(reviews.person.replace(/\n/g, "<br />").replace(/\\n/g,"<br />").replace(/\\/g,""));
                                            return alert("Textul este prea lung.");
                                        }

                                        setReviews({
                                            content: text,
                                            person: {
                                                name: person.name,
                                                type: person.type,
                                                stars: person.stars,
                                                dateinit: person.dateinit
                                            }
                                        })
                                    }}>done</span>
                                </div>
                                <div className="user-select-none">
                                    <span className="material-icons-outlined pointer" onClick={() => { setEditMode(!editMode); setText(reviews.content); setPerson(reviews.person) }}>close</span>
                                </div>
                            </div>
                            :
                            <div className="d-flex flex-row m-auto mt-3">
                                <div className="user-select-none">
                                    <span className="material-icons-outlined pointer" onClick={() => setEditMode(!editMode)}>edit</span>
                                </div>
                                <div className="user-select-none">
                                    <span className="material-icons-outlined pointer" onClick={() => removeReviews()}>delete</span>
                                </div>
                            </div>
                    )
                }
            </div>
        </div>
    )
}

const Reviews = ({ account, data }) => {
    const dispatch = useDispatch();

    const [reviewsList, setReviewsList] = useState(data);
    const [width, setWidth] = useState(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const isMobile = width <= 768;

    const [pauseSlider, setPauseSlider] = useState(false);

    useEffect(() => {
        setReviewsList(data);
        console.log(data);
    }, [data]);

    return (
        <center className="d-flex flex-column m-auto fitswell my-5">
            <h1 className={isMobile ? "text-center fs-4 text-black bold" : "text-start fs-4 text-black bold"}>Testimoniale</h1>

            <Slider className="reviews__list" {...{arrows: true,dots: false,pauseOnHover: true, pauseOnDotsHover: true,infinite: !pauseSlider,speed: 500,autoplay: !pauseSlider,fade: false,variableWidth: false,slidesToShow: isMobile ? 1 : (reviewsList.length > 4 ? 4 : reviewsList.length),slidesToScroll: 1}}>
                {
                    reviewsList && reviewsList?.map((reviews, idx) => (
                        <Review key={idx} accountType={account?.type} pause={pauseSlider} setPause={setPauseSlider} reviews={reviews} setReviews={(reviews) => {
                            setReviewsList([...reviewsList.slice(0, idx), reviews, ...reviewsList.slice(idx + 1)]);
                            dispatch(editPage({
                                value: JSON.stringify([...reviewsList.slice(0, idx), reviews, ...reviewsList.slice(idx + 1)]),
                                name: "landing_reviews_content"
                            }))
                        }}
                            removeReviews={() => {
                                setReviewsList([...reviewsList.slice(0, idx), ...reviewsList.slice(idx + 1)]);
                                dispatch(editPage({
                                    value: JSON.stringify([...reviewsList.slice(0, idx), ...reviewsList.slice(idx + 1)]),
                                    name: "landing_reviews_content"
                                }))
                            }} />
                    ))
                }
            </Slider>

            {
                account?.type=="master" &&
                <div className="m-4 user-select-none d-flex flex-row justify-content-center align-items-center">
                    <span className="material-icons-outlined pointer fs-1" onClick={() => {
                        function formatDate(date, langCode) {
                            var day = date.toLocaleString(langCode, { day: '2-digit' });   // DD
                            var month = date.toLocaleString(langCode, { month: 'short' }); // MMM
                            var year = date.toLocaleString(langCode, { year: 'numeric' }); // YYYY
                            return `${day} ${month}, ${year}`;
                        }

                        setReviewsList([...reviewsList, { content: "Adauga text aici.", person: { name: "Adauga nume aici.", stars: 5, dateinit: formatDate(new Date(), "ro-RO") } }]);
                    }}>add</span>
                </div>
            }
        </center >
    );
};

export default Reviews;