import axios from "axios";
import { SHA256 } from "crypto-js";

export const LOGIN_STARTING = 'LOGIN_STARTING';
export const LOGIN_SUCCESSFULL = 'LOGIN_SUCCESSFULL';
export const LOGIN_FAILED = 'LOGIN_FAILED';

export function loginStarting(payload) {
    return {
        type: LOGIN_STARTING,
        payload: payload
    };
}

export function loginSuccessfull(payload) {
    return {
        type: LOGIN_SUCCESSFULL,
        payload: payload
    };
}

export function loginFailed(payload) {
    return {
        type: LOGIN_FAILED,
        payload: payload
    };
}

export const logIn = (props) => {
    return async (dispatch) => {
        dispatch(loginStarting());

        axios.get(`${process.env.REACT_APP_API_KEY}/users?action=login&email=${props.email}&password=${SHA256(props.password)}`)
        .then(res => {
            dispatch(loginSuccessfull(res.data));
            
            localStorage.setItem("visitor", false);
            localStorage.setItem("token", res.data.token);
            window.location.reload();
        }).catch(err => {
            dispatch(loginFailed(err.response.data.error.message));
        });
    }
};

export const register = (props) => {
    return async (dispatch) => {
        dispatch(loginStarting());

        axios.post(`${process.env.REACT_APP_API_KEY}/users`, {
            ...props,
            password: String(SHA256(props.password))
        },
        {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Token ${localStorage.getItem("token")}`
            }
        })
        .then(res => {
            dispatch(loginSuccessfull(res.data));
            
            localStorage.setItem("visitor", false);
            localStorage.setItem("token", res.data.token);
            window.location.reload();
        }).catch(err => {
            dispatch(loginFailed(err.response.data.error.message));
        });
    }
};

export const CHECKIN_STARTING = 'CHECKIN_STARTING';
export const CHECKIN_SUCCESSFULL = 'CHECKIN_SUCCESSFULL';
export const CHECKIN_FAILED = 'CHECKIN_FAILED';

export function checkInStarting(payload) {
    return {
        type: CHECKIN_STARTING,
        payload: payload
    };
}

export function checkInSuccessfull(payload) {
    return {
        type: CHECKIN_SUCCESSFULL,
        payload: payload
    };
}

export function checkInFailed(payload) {
    return {
        type: CHECKIN_FAILED,
        payload: payload
    };
}

export const checkIn = (props) => {
    return async (dispatch) => {
        dispatch(checkInStarting());

        axios.get(`${process.env.REACT_APP_API_KEY}/users?token=${localStorage.getItem("token")}`)
        .then(res => {
            localStorage.setItem("visitor", false);
            dispatch(checkInSuccessfull(res.data));
        }).catch(err => {
            localStorage.clear();
            dispatch(checkInFailed(err.response.data.error.message));
        });
    }
};

export const checkInVisitor = (props) => {
    return async (dispatch) => {
        dispatch(checkInStarting());

        if (localStorage.getItem("visitor") == "true") {
            axios.get(`${process.env.REACT_APP_API_KEY}/visitors?token=${localStorage.getItem("token")}`)
            .then(res => {
                localStorage.setItem("visitor", true);
                dispatch(checkInSuccessfull({data: {}, token: res.data.token}));
            }).catch(err => {
                dispatch(checkInFailed(err.response.data.error.message));
            });
        }
        else {
            axios.get(`${process.env.REACT_APP_API_KEY}/visitors`)
            .then(res => {
                localStorage.setItem("visitor", true);
                dispatch(checkInSuccessfull({data: {}, token: res.data}));
            }).catch(err => {
                localStorage.clear();
                dispatch(checkInFailed(err.response.data.error.message));
            });
        }
    }
};

export const NEW_TOKEN_STARTING = 'NEW_TOKEN_STARTING';
export const NEW_TOKEN_SUCCESSFULL = 'NEW_TOKEN_SUCCESSFULL';
export const NEW_TOKEN_FAILED = 'NEW_TOKEN_FAILED';

export function newTokenStarting(payload) {
    return {
        type: NEW_TOKEN_STARTING,
        payload: payload
    };
}

export function newTokenSuccessfull(payload) {
    return {
        type: NEW_TOKEN_SUCCESSFULL,
        payload: payload
    };
}

export function newTokenFailed(payload) {
    return {
        type: NEW_TOKEN_FAILED,
        payload: payload
    };
}

export const changeData = (props) => {
    return async (dispatch) => {
        dispatch(newTokenStarting());
        dispatch(checkInStarting());

        axios.put(`${process.env.REACT_APP_API_KEY}/users`,
        {
            ...props
        }, 
        {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${localStorage.getItem("token")}`
            }
        })
        .then(res => {
            dispatch(newTokenSuccessfull(res.data));
            dispatch(checkInSuccessfull(res.data));

            alert("Ai schimbat datele contului cu succes!");
        }).catch(err => {
            dispatch(checkInFailed(err.response.data.error.message));
            dispatch(newTokenFailed(err.response.data.error.message));
        });
    }
};

export const logOut = () => {
    localStorage.clear();
    window.location.reload();
}