import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { editPage } from "../../../redux/actions/page";

const TextArea = ({ parameter }) => {
    const dispatch = useDispatch();
    const account = useSelector(state => state.account.data);
    const page = useSelector(state => state.page);

    const getPageParameter = (parameter) => {
        for (const param of page?.data) {
            if (param.name == parameter) {
                return param.type == "integer" ? Number(param.value) : param.value;
            }
        }

        return null;
    }

    const [editMode, setEditMode] = useState(false);
    const [text, setText] = useState(getPageParameter(parameter));

    const ref = useRef(null);

    useEffect(() => {
        setText(getPageParameter(parameter));
    }, [page]);

    const handleInput = (e) => {
        setText(e.target.value);

        e.target.style.height = 'inherit';
        e.target.style.height = `${e.target.scrollHeight}px`;
    }

    useEffect(() => {
        if (editMode) {
            ref.current.style.height = 'inherit';
            ref.current.style.height = `${ref.current.scrollHeight}px`;
        }
    }, [editMode]);

    return (
        <>
            {
                editMode ?
                    <textarea ref={ref} className="text-center" value={text} onChange={handleInput} />
                    :
                    <div className="whitespace-break-spaces" dangerouslySetInnerHTML={{ __html: text }}></div>
            }

            {
                account?.type == "master" &&
                (
                    editMode ?
                        <div className="d-flex flex-row justify-content-center m-auto">
                            <div className="m-4 user-select-none">
                                <span className="material-icons-outlined pointer" onClick={() => {
                                    setEditMode(!editMode);

                                    dispatch(editPage({
                                        value: text.replace(/\n/g, "<br />").replace(/\\n/g, "<br />").replace(/\\/g, ""),
                                        name: parameter
                                    }))
                                }}>done</span>
                            </div>
                            <div className="m-4 user-select-none">
                                <span className="material-icons-outlined pointer" onClick={() => { setEditMode(!editMode); setText(text) }}>close</span>
                            </div>
                        </div>
                        :
                        <div className="m-4 user-select-none">
                            <span className="material-icons-outlined pointer" onClick={() => setEditMode(!editMode)}>edit</span>
                        </div>
                )
            }
        </>
    );
};

export default TextArea;