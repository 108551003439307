export const SET_CONFIGURATOR_CATEGORY = 'SET_CONFIGURATOR_CATEGORY';
export const SET_CONFIGURATOR_TYPE = 'SET_CONFIGURATOR_TYPE';
export const SET_CONFIGURATOR_SEX = 'SET_CONFIGURATOR_SEX';
export const SET_CONFIGURATOR_SIZE = 'SET_CONFIGURATOR_SIZE';
export const SET_CONFIGURATOR_FACE = 'SET_CONFIGURATOR_FACE';
export const SET_CONFIGURATOR_COLOR = 'SET_CONFIGURATOR_COLOR';
export const SET_CONFIGURATOR_IMAGE = 'SET_CONFIGURATOR_IMAGE';

export function SetConfiguratorCategory(item) {
    return {
        type: SET_CONFIGURATOR_CATEGORY,
        item: item
    };
}

export function SetConfiguratorType(item) {
    return {
        type: SET_CONFIGURATOR_TYPE,
        item: item
    };
}

export function SetConfiguratorSex(item) {
    return {
        type: SET_CONFIGURATOR_SEX,
        item: item
    };
}

export function SetConfiguratorSize(item) {
    return {
        type: SET_CONFIGURATOR_SIZE,
        item: item
    };
}

export function SetConfiguratorFace(item) {
    return {
        type: SET_CONFIGURATOR_FACE,
        item: item
    };
}

export function SetConfiguratorColor(item) {
    return {
        type: SET_CONFIGURATOR_COLOR,
        item: item
    };
}

export function SetConfiguratorImage(item) {
    return {
        type: SET_CONFIGURATOR_IMAGE,
        item: item
    };
}

export const setConfiguratorCategory = (payload) => {
    return dispatch => {
        dispatch(SetConfiguratorCategory(payload));
    };
}

export const setConfiguratorType = (payload) => {
    return dispatch => {
        dispatch(SetConfiguratorType(payload));
    };
}

export const setConfiguratorSex = (payload) => {
    return dispatch => {
        dispatch(SetConfiguratorSex(payload));
    };
}

export const setConfiguratorSize = (payload) => {
    return dispatch => {
        dispatch(SetConfiguratorSize(payload));
    };
}

export const setConfiguratorColor = (payload) => {
    return dispatch => {
        dispatch(SetConfiguratorColor(payload));
    };
}

export const setConfiguratorFace = (payload) => {
    return dispatch => {
        dispatch(SetConfiguratorFace(payload));
    };
}
export const setConfiguratorImage = (payload) => {
    return dispatch => {
        dispatch(SetConfiguratorImage(payload));
    };
}